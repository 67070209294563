export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const isWindows =
      /Win/.test(window.navigator.platform) ||
      /Windows/.test(window.navigator.userAgent);

    if (isWindows) {
      document.body.classList.add("windows-os");
    }
  }
};
